import { applyVueInReact } from "vuereact-combined";
import React, { Component } from "react";
import axios from "@/axios";
import LoadingSpinner from "@/components/LoadingSpinner";
import Payment from "../../components/payment/Payment";

class InternetBillFirstLink extends Component {
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);

  constructor() {
    super();
    this.state = {
      loading: true,
      reports: "",
      username: "",
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  handleUserNameChange = (event) => {
    this.setState({ username: event.target.value });
  };

  checkFirstLinkAccount = async (event) => {
    event.preventDefault();

    if (!this.state.username) {
      return this.setState({
        message: "Please enter your Firstlink username.",
      });
    }

    this.setState({ loading: true });
    const data = await axios.post("/api/v2/services/firstlink/checkAccount", {
      FirstLinkUserName: this.state.username,
    });
    this.setState({ loading: false, message: "" });
    if (data.data && data.data.response) {
      if (data.data.response.Code !== "000") {
        this.setState({ message: data.data.response.Message });
      } else {
        this.setState({ reports: data.data.response });
      }
    }
  };
  render() {
    return (
      <div className="container">
        <div className="wallet-form-box card">
          <div className="card-body">
            <h5 className="card-title">Firstlink Payment.</h5>
            <a href="/" className="btn btn-close">
              <i className="fa fa-times"></i>
            </a>
            {this.state.message && (
              <div className="form-group fade-animation">
                <div className="show-message">{this.state.message}</div>
              </div>
            )}
            <div className="row">
              <div className="col-md-7">
                {this.state.reports ? (
                  <div className="fade-animation">
                    <div className="mt-3">
                      <div className="font-weight-bold">Firstlink Username</div>
                      <div>{this.state.reports.FirstLinkUserName}</div>
                    </div>
                    <div className="mt-3">
                      <div className="font-weight-bold">Customer Id</div>
                      <div>{this.state.reports.CustomerId}</div>
                    </div>
                    <div className="mt-3">
                      <div className="font-weight-bold">Customer Name</div>
                      <div>{this.state.reports.CustomerName}</div>
                    </div>
                    <div className="mt-3">
                      <div className="font-weight-bold">Address</div>
                      <div>{this.state.reports.CustomerAddress}</div>
                    </div>
                    <div className="mt-3">
                      <div className="font-weight-bold">Package</div>
                      <div>{this.state.reports.Package}</div>
                    </div>
                    <div className="mt-3">
                      <div className="font-weight-bold">Package Duration</div>
                      <div>{this.state.reports.PackageDuration}</div>
                    </div>
                    <div className="mt-3">
                      <div className="font-weight-bold">Amount</div>
                      <div>Rs. {this.state.reports.BillAmount.toFixed(2)}</div>
                    </div>
                    <div className="fade-animation">
                      <Payment
                        paymentUri="api/v4/firstlink/payment"
                        paymentPayload={{
                          FirstLinkUserName: this.state.username,
                          Amount: this.state.reports.BillAmount,
                        }}
                        setMessage={(message) => {
                          this.setState({ message });
                        }}
                        paymentPreviewDetail={{
                          "Customer Name": this.state.reports.CustomerName,
                          "Total Amount to Pay":
                            "Rs. " + this.state.reports.BillAmount,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <form>
                    <div className="form-group">
                      <label>
                        Username <i className="fa fa-asterisk"></i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Type Username and press enter."
                        value={this.state.username}
                        name="username"
                        onChange={this.handleUserNameChange}
                      />
                    </div>
                    <button
                      className="btn btn-primary mt-3"
                      onClick={this.checkFirstLinkAccount}
                    >
                      Search
                    </button>
                  </form>
                )}
              </div>
            </div>
            <this.LoadingSpinnerComp loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default InternetBillFirstLink;
